import { render, staticRenderFns } from "./list.vue?vue&type=template&id=3870cd4a&scoped=true&"
import script from "./list.vue?vue&type=script&lang=js&"
export * from "./list.vue?vue&type=script&lang=js&"
import style0 from "./list.vue?vue&type=style&index=0&id=3870cd4a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3870cd4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\wamp64\\www\\lifebase-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3870cd4a')) {
      api.createRecord('3870cd4a', component.options)
    } else {
      api.reload('3870cd4a', component.options)
    }
    module.hot.accept("./list.vue?vue&type=template&id=3870cd4a&scoped=true&", function () {
      api.rerender('3870cd4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/monitoring_and_management/contract_monitoring/components/list/list.vue"
export default component.exports